<template>
    <section class="section section--news section--background">
        <b-container>
            <div class="section__title text-center">
                <div class="start-title">
                    <img src="/assets/img/line-star.png" loading="lazy"  alt="start"/>
                </div>
                <div class="heading h2"><span>TIN TỨC</span></div>
            </div>
            <div class="row list-news show-pc">
                <ul class="list-news__home">
                    <li v-for="(item, index) in listPost" :key="index" class="list-news-item">
                        <n-link :to="item.link" class="new flex-box">
                            <div class="thumb">
                                <img loading="lazy" :src="item.thumb" :alt="item.title"/>
                            </div>
                            <div class="content">
                                <div class="heading title">
                                    {{item.title}}
                                </div>
                                <p>
                                    {{item.description}}
                                </p>
                            </div>
                        </n-link>
                    </li>
                </ul>
            </div>
            <div class="row list-news show-mb">
                <div class="swiper-container" id="swiperContainer">
                    <p class="swiper-control">
                        <button type="button" class="btn btn--arrow btn-default btn-sm prev-slide">
                            <img src="/assets/img/news/prev.svg" loading="lazy"  alt="prev"/>
                        </button>
                        <button type="button" class="btn btn--arrow btn-default btn-sm next-slide">
                            <img src="/assets/img/news/next.svg" loading="lazy"  alt="next"/>
                        </button>
                    </p>
                    <div class="swiper-wrapper">
                        <div class="swiper-slide list-news-item" v-for="(item, index) in listPost" :key="index">
                            <n-link :to="item.link" class="new flex-box">
                                <div class="thumb">
                                    <img loading="lazy" :src="item.thumb" :alt="item.title"/>
                                </div>
                                <div class="content">
                                    <div class="heading title">
                                        {{item.title}}
                                    </div>
                                    <p>
                                        {{item.description}}
                                    </p>
                                </div>
                            </n-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section-readmore flex-box just-center">
                <n-link to="/tin-tuc">XEM THÊM <i class="las la-arrow-right"></i></n-link>
            </div>
        </b-container>
    </section>
</template>

<script>

export default {
    data() {
        return {
            listPost: [
                {
                    title: 'Soi Kèo Bóng Đá Hôm Nay',
                    description: 'Nhà cái Oxbet soi kèo tài xỉu, kèo châu á, kèo châu âu,... Cam kết kèo chuẩn, chính xác, uy tín ở các giải ngoại hạng Anh, Đức, Ý, Pháp, Tây Ban Nha,...',
                    link: '/the-loai/soi-keo-bong-da-hom-nay',
                    thumb: '/assets/img/news/post-new.jpg'
                },
                {
                    title: 'Nổ Hũ',
                    description: 'Kinh nghiệm, cách chơi, quy luật game nổ hũ. Nổ hũ Oxbet, đẳng cấp game thời thượng',
                    link: '/the-loai/no-hu',
                    thumb: '/assets/img/news/post-nohu.jpg'
                },
                {
                    title: 'Lô Đề Online',
                    description: 'Nhà cái Oxbet chia sẻ Bạch Thủ Lô Bắc Trung Nam chuẩn chỉnh. Thần lô, thánh đề sẽ giải mộng, đoán điềm, cho số giúp con dân chiến thắng nhà cái Oxbet',
                    link: '/the-loai/lo-de-online',
                    thumb: '/assets/img/news/post-lode.jpg'
                },
                {
                    title: 'Slot Game',
                    description: 'Cập nhật thông tin những Game Slots đổi thưởng mới nhất của Việt Nam và Quốc Tế. Mẹo chơi Game Slots dễ thắng',
                    link: '/the-loai/slot-game',
                    thumb: '/assets/img/news/slot-game.jpg'
                },
                {
                    title: 'Poker',
                    description: 'Phương pháp, thủ thuật cho người mới chơi game bài Poker. Tuyệt chiêu chiến thắng nhà cái khi chơi bài Poker',
                    link: '/the-loai/poker',
                    thumb: '/assets/img/news/poker.jpg'
                }
            ]
        };
    },
    methods: {
        initSlider() {
            this.$swiper('#swiperContainer', {
                mode: 'horizontal',
                loop: false,
                slidesPerView: 3,
                autoplay: false,
                navigation: {
                    nextEl: '.next-slide',
                    prevEl: '.prev-slide'
                },
                breakpoints: {
                    1024: {
                        slidesPerView: 3
                    },
                    992: {
                        autoplay: true,
                        slidesPerView: 2
                    },
                    768: {
                        autoplay: true,
                        slidesPerView: 2
                    },
                    600: {
                        autoplay: true,
                        slidesPerView: 2
                    },
                    320: {
                        autoplay: true,
                        slidesPerView: 1
                    }
                }
            });
        }
    },
    mounted() {
        this.$nextTick(async () => {
            this.initSlider();
        });
    }
};
</script>
